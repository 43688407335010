import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../_configuration/configuration';

const config = AppConfig.getInstance();

@Injectable({
    providedIn: 'root',
})
export class LoginService
{
    constructor(private http: HttpClient) { }

    login(email: string, password: string, cultureCode: string, fingerprint: string, deviceInfoJson: string): Observable<any>
    {
        const body = { email, password, cultureCode, fingerprint, deviceInfoJson, packageName: "com.frontgatesdev.emaildownloader" };
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.http.post<any>(config.webApiBaseUrl + '/v1/iam/auth/authenticate', body, { headers });
    }

    loginByPushNotificationToken2(pushNotificationToken: string, cultureCode: string, fingerprint: string, deviceInfoJson: string): Observable<any>
    {
        const body = { pushNotificationToken, cultureCode, fingerprint, deviceInfoJson, packageName: "com.frontgatesdev.emaildownloader" };
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.http.post<any>(config.webApiBaseUrl + '/v1/iam/auth/authenticate', body, { headers });
    }

    loginByPushNotificationToken(pushNotificationToken: string, cultureCode: string, fingerprint: string, deviceInfoJson: string): Observable<any>
    {
        const body = {
            pushNotificationToken,
            cultureCode,
            fingerprint,
            deviceInfoJson,
            packageName: "com.frontgatesdev.emaildownloader"
        };

        return new Observable(observer =>
        {
            fetch(`${config.webApiBaseUrl}/v1/iam/auth/authenticate`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
                .then(response =>
                {
                    if (!response.ok)
                    {
                        // If the response is not ok, reject the observable
                        observer.error('Network response was not ok');
                        return;
                    }
                    return response.json(); // Parse the JSON response
                })
                .then(data =>
                {
                    observer.next(data); // Emit the data
                    observer.complete(); // Mark the observable as complete
                })
                .catch(error =>
                {
                    console.error('There was a problem with the fetch operation:', error);
                    observer.error(error); // Emit the error
                });
        });
    }
}
