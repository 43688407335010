import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { AuthenticationService } from './iam/authentication.service';
import { LoginService } from './iam/login.service';
import { DeviceInfoService } from './identifiers/device-info.service';
import { MenuService } from './menu.service';
import { SignalRService } from './signalr.service';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService
{

    constructor(private deviceInfoService: DeviceInfoService, private authenticationService: AuthenticationService, private menuService: MenuService, private signalRService: SignalRService, private loginService: LoginService)
    {

    }

    public authenticate(success?: any): Observable<any>
    {
        const pushNotificationToken: string | null = localStorage.getItem("pushNotificationToken");

        if (pushNotificationToken)
        {
            const fingerprint = this.deviceInfoService.fingerprint.toString();
            const deviceInfoJson = this.deviceInfoService.deviceInfoJson;

            return this.loginService.loginByPushNotificationToken(pushNotificationToken, 'en-CA', fingerprint, deviceInfoJson).pipe(
                tap(response =>
                {
                    if (response && response.AuthToken?.access_token)
                    {
                        // Handle the response and set all the necessary values
                        this.authenticationService.setApplicationId(response.ApplicationId);
                        this.authenticationService.setClientId(response.ClientId);
                        this.authenticationService.setDeviceId(response.DeviceId);
                        this.authenticationService.setAuthToken(response.AuthToken);
                        this.authenticationService.setEmail(response.Email);
                        this.authenticationService.success();

                        // Load menu and initialize signalR
                        this.menuService.loadMenu(response.ApplicationId, response.ClientId, response.DeviceId);
                        this.signalRService.initialize();

                        if (success)
                            success();
                    }
                }),
                catchError(error =>
                {
                    console.error('Error during push notification authentication:', error);
                    return of(null); // Return null or handle error appropriately
                })
            );
        }

        // If no push notification token, return an observable with null
        return of(null);
    }
}
